@import "@/src/styles/common.scss";
@import "../_common.module.scss";
// size: large, small, medium
// button-color
$primaryFontColor: #fff;
// height
$largeHeight: auto;
$mediumHeight: auto;
$smallHeight: 0.6rem;
// font-size
$largeFontSize: 0.36rem;
$mediumFontSize: 0.28rem;
$smallFontSize: 0.28rem;
// padding
$largePaddingLR: 0.4rem;
$mediumPaddingLR: 0.44rem;
$smallPaddingLR: 0.44rem;
$largePaddingTB: 0.18rem;
$mediumPaddingTB: 0.18rem;
$smallPaddingTB: 0; //0.18rem;

// border-radius
$largeBorderRadius: 0.08rem;
$mediumBorderRadius: 0.08rem;
$smallBorderRadius: 0.08rem;

// functions-mixins
// button-warning-color
@mixin warning() {
    &.warning {
        background: $warningColor;
        color: $white;
        border: none;
    }
}

// 按钮尺寸
@mixin buttonSize(
    $paddingTB: $mediumPaddingTB,
    $fontSize: $mediumFontSize,
    $paddingLR: $mediumPaddingLR,
    $borderRadius: $mediumBorderRadius,
    $height: $mediumHeight
) {
    text-align: center;
    line-height: 1.5;
    height: $height;
    font-size: $fontSize;
    padding: $paddingTB $paddingLR;
    border-radius: $borderRadius;
    font-family: var(--font-montserrat-medium);
    * {
        font-size: inherit;
        font-family: inherit;
        line-height: inherit;
    }
}

@mixin textButtonSize($fontSize: $mediumFontSize, $size: $mediumPaddingSize) {
    text-align: center;
    line-height: 1.5;
    font-size: $fontSize;
    padding: 0 $size;
    font-family: var(--font-montserrat-medium);
    *:not(.icon) {
        font-size: inherit;
        font-family: inherit;
    }
}

// 移动端要考虑的尺寸
// 移动端的样式，不同尺寸的样式显示，如width, height, font-size,border-radius等
// 移动端交互效果：disabled, hover等
.root.button_container {
    text-transform: none !important;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    text-align: center;
    min-width: auto;
    letter-spacing: normal;
    // variant-.(color | size)
    &.disabled.disabled_color {
        background-color: $disabled;
        pointer-events: none;
        color: $white;
        border: 0.02rem solid $disabled;
    }
    // common-css
    &.contained,
    &.outlined,
    &.dark {
        // color: 目前只支持warning
        @include warning();

        // 循环打印不同尺寸的不同样式
        @each $size, $paddingTB, $fontSize, $paddingLR, $borderRadius,
            $height
                in (
                    large,
                    $largePaddingTB,
                    $largeFontSize,
                    $largePaddingLR,
                    $largeBorderRadius,
                    $largeHeight
                ),
            (
                medium,
                $mediumPaddingTB,
                $mediumFontSize,
                $mediumPaddingLR,
                $mediumBorderRadius,
                $mediumHeight
            ),
            (
                small,
                $smallPaddingTB,
                $smallFontSize,
                $smallPaddingLR,
                $smallBorderRadius,
                $smallHeight
            )
        {
            &.#{$size}:not(.full-width) {
                @include buttonSize(
                    $paddingTB,
                    $fontSize,
                    $paddingLR,
                    $borderRadius,
                    $height
                );
                &:hover {
                    @include buttonSize(
                        $paddingTB,
                        $fontSize,
                        $paddingLR,
                        $borderRadius,
                        $height
                    );
                }
            }

            &.#{$size}.full-width {
                @include buttonSize(
                    $paddingTB,
                    $fontSize,
                    $mediumPaddingLR,
                    $borderRadius,
                    $height
                );
                width: 100%;
                &:hover {
                    @include buttonSize(
                        $paddingTB,
                        $fontSize,
                        $mediumPaddingLR,
                        $borderRadius,
                        $height
                    );
                }
            }
        }
    }
    // text模块应该在这里，但是为了让外部覆盖不要这么难受，所以降低其css权重，放到外面去
    // width
    .firmoo-button-full {
        width: 100%;
        height: 0.97rem;
        line-height: 0.97rem;
        font-size: 0.32rem;
        font-family: var(--font-montserrat-medium);
        border-radius: 0;
        text-align: center;
    }
    .firmoo-button-half {
        width: 50%;
        height: 0.97rem;
        line-height: 0.97rem;
        font-size: 0.32rem;
        border-radius: 0;
        text-align: center;
    }
    // variant
    &.contained {
        background: $main;
        color: $white;
        box-shadow: none;
        &.disabled {
            background-color: $gray_4;
        }
        &:hover {
            box-shadow: inset 0px 96px 0px 0px rgb(0 0 0 / 10%);
        }
    }
    &.outlined {
        background: transparent;
        color: $main;
        border: 0.02rem solid $main;
        &.disabled {
            background-color: transparent;
            color: $text_50_percent; // 经由设计要求，outlined的disabled状态按钮也是这个颜色
            border-color: $text_50_percent;
        }
        &:hover {
            // background-color: $main;
            // color: $main;
        }
    }
    &.text {
        background: $textTransparentColor;
        border: none;
        color: $main;
    }
    &.dark {
        background: $white;
        border: 0.02rem solid $maingray;
        color: $maingray;
    }
    // icon↓
    .icon {
        @include use-icon-normal();
        &.icon-left {
            margin-right: 0.32rem;
        }
        &.icon-right {
            margin-left: 0.32rem;
        }
    }
    .button-container {
        position: relative;
        display: flex;
        &.visibility-hidden {
            visibility: hidden;
        }
    }
    .loading-animation {
        font-size: 0.72rem;
        position: absolute;
        margin: 0 auto;
        animation: loading 1.5s linear infinite;
    }
}

// text类型不需要variant
.text {
    // color: 目前只支持warning
    @include warning();
    font-family: var(--font-montserrat-medium);
    // 循环打印不同尺寸的不同样式
    @each $size, $fontSize,
        $paddingLR in (large, $largeFontSize, $largePaddingLR),
        (medium, $mediumFontSize, $mediumPaddingLR),
        (small, $smallFontSize, $smallPaddingLR)
    {
        &.#{$size}:not(.full-width) {
            @include textButtonSize($fontSize, 0);
            &:hover {
                // @include textButtonSize($fontSize, 0);
            }
        }
        &.#{$size}.full-width {
            @include textButtonSize($fontSize, $paddingLR);
            width: 100%;
            &:hover {
                // @include textButtonSize($fontSize, $paddingSize);
            }
        }
    }
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
