@import "@/src/styles/common.scss";
@mixin ellipsis() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.form_control + .form_control {
    margin-top: 0.3rem;
}
.form_control {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: max-content;
    .error-bottom-tips {
        color: var(--error-color);
        font-size: 0.24rem;
        margin-top: 0.1rem;
    }
    .error {
        transition: all 0.3s;
        opacity: 0;
        height: 0;
        padding: 0;
        margin-bottom: 0;
        &.help-text {
            align-self: flex-end;
            position: relative;
            font-size: 0.24rem;
            color: #fff;
            border-radius: 0.08rem;
            overflow-wrap: break-word;
            max-width: 100%;
            background-color: var(--error-color);
            &.helptext-animation::after {
                content: "";
                position: absolute;
                width: 0.15rem;
                height: 0.15rem;
                background: inherit;
                bottom: -0.05rem;
                right: 0.25rem;
                transform: scaleY(1.8) rotate(35deg);
            }
        }
        &.helptext-animation {
            padding: 0.1rem;
            opacity: 1;
            height: auto;
            margin-bottom: 0.2rem;
        }
    }
    .input-content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: var(--default-height);
        .root_input {
            width: 100%;
            padding: 0;
            &.border-radius-round {
                border-radius: 0.35rem;
                .input_content {
                    height: 0.7rem;
                    width: 100%;
                    border: none;
                    padding: 0px 0.8rem 0px 10px;
                    font-size: 0.28rem;
                    background: #f2f2f2;
                    line-height: 0.7rem;
                    border-radius: 0.34rem;
                }
            }
            &.border-radius-square {
                height: 100%;
                border-radius: 0.08rem;
                &.border-status- {
                    &normal {
                        border: 0.02rem solid var(--border-color);
                    }
                    &error {
                        border: 0.02rem solid var(--error-color);
                    }
                    &focus {
                        border: 0.02rem solid $main;
                    }
                    &disabled {
                        border: 0.02rem solid $disabled_color !important;
                    }
                }
            }
            .input_content {
                height: auto;
                padding: 0 0.14rem;
                box-sizing: border-box;
                // 极限操作，不要问我为什么，你问问input的默认样式表怎么这么难覆盖
                border-top: 0.02rem solid;
                border-top-color: inherit;
                border-bottom: 0.02rem solid;
                border-bottom-color: inherit;
                border: none;
                @include ellipsis();
                background: transparent; //背景透明
                -webkit-tap-highlight-color: transparent;
                @include use-font-normal();
                &::-webkit-input-placeholder {
                    color: $placeholderColor;
                    font-size: 0.24rem;
                    opacity: 1;
                    font-family: var(--font-montserrat);
                }
            }
            .adornment {
                border-radius: 50%;
                background-color: $placeholderColor;
                width: auto;
                height: auto;
                position: absolute;
                right: 0.3rem;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0.08rem;
                .close_icon {
                    color: #fff;
                    height: auto;
                    font-size: 0.24rem;
                }
            }
            // label
            .notched-out-line {
                border: none;
                top: 0;
                transform: translateY(-50%);
                height: max-content;
                legend {
                    height: auto;
                    margin: 0;
                    font-size: 0.22rem;
                    line-height: 1;
                    width: 100%;
                    max-width: unset;
                    border-bottom: none;
                }
                span {
                    line-height: 1;
                    font-size: 0.22rem;
                    opacity: 1;
                    color: #a6abaf;
                    transition: all 0.3s ease 0s;
                    background: #ffffff;
                    font-family: var(--font-montserrat);
                }
            }
        }

        .search_icon_btn {
            width: 0.68rem;
            height: 0.68rem;
            background: $main;
            border-radius: 50%;
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: center;
            margin-left: 0.3rem;
            font-size: 0.4rem;
        }
    }
}
